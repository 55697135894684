import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <>
      <p>Loading</p>
    </>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `shonibarestudio`,
  linkResolver: ({ node, key, value }) => doc => {
    // 	// Your link resolver
    if (doc.uid === "home") {
      return `/`;
    } else if (doc.type === "splash") {
      return `/splash/`;
    } else if (doc.type === "artwork") {
      return `/artwork/${doc.uid}/`;
    } else if (doc.type === "exhibition") {
      return `/exhibitions/${doc.uid}/`;
    } else if (doc.type === "news_article") {
      return `/news/${doc.uid}/`;
    } else {
      return `/${doc.uid}/`;
    }
  },
});
